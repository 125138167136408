/* purgecss start ignore */
@tailwind base;
@tailwind components;
/* purgecss end ignore */
@tailwind utilities;


.no-scroll {
  overflow: hidden;
}

.tag {
  @apply px-2 py-1 bg-slate-200 mr-2 rounded-md;
}

.banner h1 {
  @apply text-6xl md:text-8xl font-bold tracking-tighter leading-tight;
  
}

.banner h3 {
  @apply text-3xl md:text-4xl font-bold tracking-tighter leading-tight;
  
}

.banner h1 strong {
  @apply bg-clip-text pt-1 pr-1 text-transparent bg-gradient-to-bl from-blue-300 to-blue-400 dark:to-blue-200;
  
}

.banner p {
  @apply text-lg leading-loose md:text-xl mt-5 mb-8;
}

.banner .grid p {
  @apply text-base md:text-lg lg:text-xl mt-5 mb-8;
}

a[href*="calendly"], .project-content a[href*="calendly"], .btn {
  @apply text-xl inline-block py-4 px-5 leading-4 mb-8 w-fit bg-blue-300 text-white transition duration-300 font-semibold rounded-3xl hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75;
}

.banner img[src$=".jpg"] {
  @apply rounded-3xl;
}

hr {
  @apply my-12 border-white dark:border-slate-300;
}

.project {
  /* @apply rounded-3xl overflow-hidden; */
}

.project-info {
  @apply block;
}

.project-image {
  @apply rounded-md md:rounded-xl lg:rounded-3xl block overflow-hidden;
}

.project-info h3{
  @apply transition-colors;
}

.project:hover .project-info h3 {
  @apply text-blue-200;
}

.project .project-image-link {
  @apply block overflow-hidden rounded-xl md:rounded-2xl lg:rounded-3xl focus:outline focus:outline-2 focus:outline-blue-300;
}

.project .project-image {
  @apply duration-150 ease-in delay-150;
  transition: .5s ease-in-out opacity,  .25s ease-in-out mix-blend-mode, .25s ease-in filter, .5s ease-in-out transform;
}

.project:hover .project-image {
  @apply opacity-90 scale-105;
}

.project.video:hover .project-image {
  @apply md:blur-sm opacity-80;
}

.project .project-video-wrapper {
  @apply duration-300 ease-in delay-150 blur-sm opacity-0 scale-75 md:h-full md:w-72 lg:w-96 lg:h-full overflow-hidden;
}

.project .project-video-wrapper video {
  @apply w-full;
}

.project:hover .project-video-wrapper {
  @apply blur-none md:opacity-100 md:scale-95 lg:scale-100;
}

.project-content {
  @apply text-xl md:text-2xl leading-loose;
}

.project-content h1 {
  @apply text-5xl md:text-6xl mb-8 leading-tight;
}

.project-content h3 {
  @apply text-3xl md:text-4xl mb-4 leading-tight;
}

.project-content h4 {
  @apply text-xl md:text-2xl mb-4;
}

.project-content h5 {
  @apply text-2xl md:text-3xl font-bold mb-4;
}

.project-content h6 {
  @apply text-lg md:text-xl mb-4;
}

.project-content p, .project-content ul, .project-content ol {
  @apply mb-8 font-light leading-loose;
}

.project-content ul {
  @apply list-disc ml-4;
}

.project-content h6 + ul {
  @apply text-lg;
}

.project-content li {
  @apply mb-2 pl-4;
}

.project-content a {
  @apply text-blue-300 font-bold;
}

.project-content em {
  @apply text-base lg:text-lg mb-16;
}

.project-content img[src$=".jpg"], .project-content img[src$=".png"], .project-video  {
  @apply mb-16 rounded-xl md:rounded-2xl mx-auto overflow-hidden;
}

.project-video {
  @apply rounded-[40px] 
}

.project-content img + em  {
  @apply text-center block -mt-12;
}

.case-studies:first-child .case-studies-title {
  @apply text-5xl md:text-6xl lg:text-7xl font-bold tracking-tighter leading-tight md:leading-none text-center md:text-left;
}

.post + hr + .more-stories {
  @apply max-w-4xl;
}

.post + hr + .more-stories .project-video-wrapper {
  @apply w-72 drop-shadow-lg;
}

.post + hr + .more-stories .project-video {
  @apply rounded-[32px];
}


.case-studies:first-child .grid{
  /* @apply lg:grid-cols-2 xl:grid-cols-3 */
}

:not(.case-studies) > .py-8 > .grid > :nth-child(3){
  @apply block md:hidden xl:block;
}

img[alt="Juan Salazar"] {
  @apply rounded-full max-w-xs mb-12;
}